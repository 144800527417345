/* == ADD SOME FLEX-BOX SUGAR == */
/* == ADD SUGAR FOR OTHER PREFIX CSS == */
/* == FONTS == */
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
/* == LAYOUT SYSTEM FOR FLEX-BOX "TABLES" == */
.vertical {
  display: flex;
  flex-direction: column;
}

.horizontal {
  display: flex;
  flex-wrap: nowrap;
}

.center {
  display: flex;
  justify-content: center;
}

.centerXA {
  display: flex;
  align-items: center; /* Cross axis alignment */
}

.right {
  display: flex;
  justify-content: right;
}

.stretch {
  display: flex;
  justify-content: stretch;
}

.stretchXA {
  display: flex;
  align-items: stretch;
}

.box-1 {
  flex: 1;
}

.box-2 {
  flex: 2;
}

.box-3 {
  flex: 3;
}

.box-4 {
  flex: 4;
}

.box-5 {
  flex: 5;
}

.box-6 {
  flex: 6;
}

.box-7 {
  flex: 7;
}

.box-8 {
  flex: 8;
}

.box-9 {
  flex: 9;
}

.box-10 {
  flex: 10;
}

.box-11 {
  flex: 11;
}

.box-12 {
  flex: 12;
}

.box-13 {
  flex: 13;
}

.box-14 {
  flex: 14;
}

.box-15 {
  flex: 15;
}

.box-16 {
  flex: 16;
}

.box-17 {
  flex: 17;
}

.box-18 {
  flex: 18;
}

.box-19 {
  flex: 19;
}

.box-20 {
  flex: 20;
}

/* == MAIN == */
/*COLORS*/
/*#FF3B30;*/
/*"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;*/
/* == ANIMATION DURATION == */
/* == BODY == */
html {
  height: 100%;
}

body {
  font-family: Tahoma, "Helvetica Neue";
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(33, 33, 33);
  color: rgb(245, 245, 245);
}

/* == SMALL STUFF == */
input {
  background-color: #2e2e2e;
  color: rgb(5, 218, 254);
}

.dataAge {
  color: #b5b5b5;
  font-style: italic;
  justify-content: center;
}

.footer {
  color: #427d9a;
  /*font-style: italic;*/
  justify-content: space-between;
  align-items: flex-end;
}

/* == ALIGN == */
.alignLeft {
  text-align: right;
  justify-content: flex-start;
}

.alignRight {
  text-align: right;
  justify-content: flex-end;
}

/* VERTICAL */
.middle {
  display: flex;
  align-items: center;
}

.hourglassHolder {
  height: 3px;
}

.hourglass {
  height: 3px;
  opacity: 0;
  width: 100%;
  background-color: rgb(100, 0, 200);
  animation: makeSmaller 35s linear 0s;
}

@keyframes makeSmaller {
  0% {
    background-color: rgb(0, 200, 0);
    width: 100%;
  }
  1% {
    opacity: 1;
  }
  60% {
    background-color: rgb(0, 200, 0);
  }
  65% {
    background-color: rgb(245, 245, 0);
  }
  90% {
    background-color: rgb(255, 59, 48);
  }
  100% {
    background-color: rgb(255, 59, 48);
    width: 0%;
    opacity: 1;
  }
}
/* == BUTTONS == */
.btn {
  border-radius: 28px;
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  text-decoration: none;
}

.btn:hover {
  background: #999;
  text-decoration: none;
}

/* == LINKS == */
a {
  /*text-decoration: none;*/
  color: rgb(245, 245, 245);
}

a:visited {
  color: #b5b5b5;
}

.error a,
.error a:visited {
  color: rgb(50, 50, 50);
}

/*http://css-tricks.com/snippets/css/style-links-depending-on-destination/*/
/* External links 
a[href^="//"]:after,
a[href^="http://"]:after,
a[href^="https://"]:after {
  content: url(../img/Icon_External_Link.svg);
  margin: 0 0 0 0.05rem;
}*/
a.no-link-style {
  color: inherit;
  text-decoration: inherit;
}

.linkLike {
  cursor: pointer;
  text-decoration: underline;
}

.linkLike:hover {
  color: #c2c2c2;
}

/* == TABLES == */
.noMatch {
  font-style: italic;
}

.searchMatch {
  line-height: 1.9;
}

.error {
  background-color: #fffc30;
  color: rgb(33, 33, 33);
  border-radius: 20px;
  border: solid #c9c600 7px;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.error .header {
  background-color: #fffc30;
}

.transportFilters {
  background-color: #2e2e2e;
}

/*Show active filters*/
.filter-true,
.filter-false {
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(245, 245, 245);
}

.filter-true {
  background-color: rgb(0, 150, 0);
}

.filter-true:hover {
  background-color: rgb(0, 100, 0);
}

.filter-false {
  background-color: rgb(150, 0, 0);
  border-radius: 3px;
}

.filter-false:hover {
  background-color: rgb(100, 0, 0);
}

.stationName {
  color: #e6e6e6;
  font-weight: bold;
}

.header {
  /*background-color: darken($default-color, 45%);*/
  text-align: left;
  font-weight: bold;
}

.departure {
  font-family: "Roboto Condensed", "Lucida Sans", "Lucida Grande", "Lucida", "Lato";
  line-height: 1.5;
}

.updating {
  animation: fadeIn 0.2s linear 0s;
  color: rgb(128, 128, 128);
  font-style: italic;
}

.lineNumber {
  color: gainsboro;
}

.journeyDirection0 {
  border-left: solid rgb(100, 0, 0) 0.3rem;
}

.journeyDirection1 {
  border-left: solid rgb(0, 0, 0) 0.3rem;
}

.journeyDirection2 {
  border-left: solid rgb(201, 201, 201) 0.3rem;
}

.deviations {
  display: none;
  animation: fadeIn 0.4s linear 0s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 0%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
.deviation {
  font-family: Tahoma, "Helvetica Neue";
  color: #cfcfcf;
}

.spDeviation {
  background-color: #2e2e2e;
  font-family: Tahoma, "Helvetica Neue";
  color: #cfcfcf;
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.severity-high {
  border-left: solid rgb(234, 0, 0) 0.3rem;
  padding-left: 0.3rem;
}

.severity-low {
  border-left: solid rgb(31, 38, 184) 0.3rem;
  padding-left: 0.3rem;
}

/* Striped */
.striped {
  border: 1px solid #2e2e2e;
}

/*section.striped:nth-child(2n-1) {
  background-color: darken($default-color, 45%);
}
section.striped:nth-child(2n) {
  background-color: darken($default-color, 40%);
}*/
.soon {
  color: #fffc30;
}

[class*=similar] {
  border: solid rgb(34, 114, 34) 1px;
}

.similarLine {
  background-color: #353535;
}

.similarLine-0 {
  background-color: #353535;
  border-color: rgb(33, 33, 33);
}

.similarLine-1 {
  background-color: #304949;
  border-color: #1e3737;
}

.similarLine-2 {
  background-color: #303049;
  border-color: #1e1e37;
}

.similarLine-3 {
  background-color: #493049;
  border-color: #371e37;
}

.similarLine-4 {
  background-color: #494630;
  border-color: #37341e;
}

.similarLine--1 {
  background-color: #9a1b1b;
  border-color: #371e1e;
}

.StopPointDesignation {
  background-color: #3b3b3b;
  border-radius: 0.3rem;
  padding: 0.2rem;
  text-align: center;
  vertical-align: middle;
}

/*.runningLate {
  color: darken($default-foreground-color, 25%);
  margin-left: 0.2rem;
}*/
.runningLate-littleLate,
.runningLate-late,
.runningLate-veryLate {
  border-radius: 0.3rem;
  padding: 0.15rem;
  margin-left: 0.2rem;
  font-size: 0.4rem;
  color: rgb(245, 245, 245);
  text-align: center;
  vertical-align: middle;
}

.runningLate-littleLate {
  background-color: rgb(50, 50, 110);
}

.runningLate-late {
  background-color: rgb(178, 138, 19);
}

.runningLate-veryLate {
  background-color: rgb(120, 40, 40);
}

/* == TRANSPORT MODE == */
img {
  max-width: 100%;
  height: auto;
  /*max-height: 40px;*/
}

/* == LOADING ANIMATION == */
/* http://css-tricks.com/snippets/css/bouncy-animated-loading-animation/ */
/*  <div class="loader">
<span></span>
<span></span>
<span></span>
</div>*/
.loader {
  text-align: center;
}

.loader span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin: 10px auto;
  background: purple;
  border-radius: 50px;
  animation: loader 0.9s alternate 0s;
}

.loader span:nth-of-type(2) {
  animation-delay: 0.3s;
}

.loader span:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
  }
}
/* == PAUSE MODAL == */
.pauseModal {
  z-index: 10;
  box-sizing: border-box;
  position: fixed;
  top: -2em;
  left: 40%;
  outline: solid 0 rgba(25, 25, 25, 0.85);
  padding: 0.5em;
  width: 5em;
  height: 2em;
  opacity: 0;
  color: rgb(250, 250, 250);
  background: rgb(226, 89, 12);
  text-align: center;
  animation: showup 0.8s forwards 0.1s;
}

@keyframes showup {
  from {
    top: 0%;
    opacity: 0;
    outline-width: 500em;
  }
  to {
    top: 10%;
    opacity: 1;
    outline-width: 500em;
  }
}
.clock {
  font-weight: bold;
  font-family: monospace;
  /*color: rgb(50,220,220);*/
  color: gainsboro;
  text-shadow: 0px 0px 0.2em #000;
}

.show {
  display: inline;
}

.geo {
  text-shadow: 0px 0px 0.4em #000;
}

/* Menu look and config */
/* Adapt font-size to screen size */
/* == DEFAULT FONTS SIZE == */
.pauseModal {
  font-size: 1rem;
}

.searchMatch {
  font-size: 1.1rem;
}

.stationName {
  font-size: 1.1rem;
}

.departure {
  font-size: 1rem;
}

.infoScroll {
  font-size: 0.7rem;
}

.deviations {
  font-size: 0.75rem;
}

.spDeviation {
  font-size: 0.8rem;
}

.dataAge {
  font-size: 0.7rem;
}

.footer {
  font-size: 0.55rem;
}

.updating {
  font-size: 0.9rem;
}

.stationSearch {
  font-size: 1.5rem;
}

.error {
  font-size: 0.8rem;
}

.menu {
  font-size: 0.6rem;
}

.filter-true {
  font-size: 0.75rem;
}

.filter-false {
  font-size: 0.75rem;
}

/* == ADAPT FONTS SIZE ACCORDING TO SCREEN SIZE == */
/* Start with smallest first, since last match "wins" */
/* https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Media_queries */
/* http://www.w3.org/blog/CSS/2012/06/14/unprefix-webkit-device-pixel-ratio/ */
/* Smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
  html {
    font-size: 15px;
  }
  img {
    max-height: 17px;
  }
}
@media (min-width: 375px) {
  html {
    font-size: 16px;
  }
  img {
    max-height: 19px;
  }
}
/* Portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
  html {
    font-size: 17px;
  }
  img {
    max-height: 19px;
  }
}
/* Portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
  html {
    font-size: 27px;
  }
  img {
    max-height: 30px;
  }
}
/* Tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  html {
    font-size: 33px;
  }
  img {
    max-height: 35px;
  }
}
/* Big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  html {
    font-size: 38px;
  }
  img {
    max-height: 40px;
  }
}
/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  html {
    font-size: 40px;
  }
  img {
    max-height: 42px;
  }
}
/* XXX */
/* ----------- Apple Watch ----------- */
